<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <BaseDatatable v-if="dataModel"
          :key="componentKey"
          :listData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault="filters"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          v-on:action-click="onActionClicked"
          v-on:callback="callback"
        ></BaseDatatable>
      </div>
    </div>
    <Dialog header="Delete" :visible.sync="showDeleteDialog" :contentStyle="{ overflow: 'visible' }" :modal="true">
      Are you want to delete this notification ?
      <template #footer>

        <Button label="Cancel" @click="showDeleteDialog = false" icon="pi pi-times" class="p-button-warning" />
        <Button label="Confirm" @click="deleteData()" icon="pi pi-check"
                class="p-button-danger  btn-confirm-delete-dialog" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
export default {
  components: {
    BaseDatatable
  },
  data() {
    return {
      filters: {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      description: '',
      showDeleteDialog: false,
      deleteId: null,
      deleteName: null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      }
    }
  },
  mounted () {
    this.initParams();
  },
  async created () {
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_NOTIFICATION);
    console.log("arr_role====",arr_role)
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route' () {
      this.initParams(); 
    }
  },
  methods: {
    callback(col, item) {
      if (col === 'delete') {
        this.showDeleteDialog = true;
        this.deleteId = item.data.id;
        this.deleteName = item.data.title;
        this.editDialogKey++;
      }
    },
    deleteData() {
      this.showDeleteDialog = false
      var query = DataServices.updateData(this.modelName)
      var variables = {
        'data_update': {deleted: true, },
        'where_key': {
          id: {'_eq': this.deleteId}
        }
      }
      this.$apollo.mutate({
        mutation: query,
        variables: variables
      }).then(async () => {
        var query_2 = DataServices.updateData("notification_users")
        var variables_2 = {
          'data_update': {deleted: true, },
          'where_key': {
            notification_id: {'_eq': this.deleteId}
          }
        }
        await this.$apollo.mutate({
          mutation: query_2,
          variables: variables_2
        }).then(() => {
          var router_back = {path: '/admin/list-notification'};
          if (!this.$commonFuction.isEmpty(router_back)){
            console.log("this.page_transaction:",this.page_transaction);
            router_back.query = { page_transaction: this.page_transaction.toString() };
          }
          this.$router.push(router_back);
          this.$commonFuction.is_loading(false);
          this.$toast.add({ severity: 'success', summary: 'Thông báo', detail: 'Xóa dữ liệu thành công', life: 3000 })
          //this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          // window.location.reload()
          this.componentKey++;
        }).catch(() => {
          this.$toast.add({ severity: 'error', summary: 'Thông báo', detail: 'Xóa dữ liệu thất bại', life: 3000 })
        })
      }).catch(() => {
        this.$toast.add({ severity: 'error', summary: 'Thông báo', detail: 'Xóa dữ liệu thất bại', life: 3000 })
      })
    },
    initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }
      actions.rows = function (rowData) {
        var buttons = {}
        if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`}
        }
        if(that.role.delete){
          buttons.delete = { name: 'delete', icon: 'trash', key: 'id'}
        }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path})
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
        this.dialogMode = action.name
        if (action.path !== undefined && action.path) {
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
        } else  {
          this.editId = data.data[action.key]
          this.displayDialog = true
          this.editDialogKey++
        }
        break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
      }
    }
  },
  apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        return DataServices.getList(this.modelName);
      },
      variables() {
        return {
          where_key: {
            active: {'_eq': true},
            deleted: {'_eq': false}
          },
          orderBy: {created_at: 'desc'}
        }
      },
      update(data) {
        var list = data[Object.keys(data)];
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(list)
        this.$store.commit('setLoading', false);
        return list;
      },
      fetchPolicy: "network-only",
      skip() {
        return !this.modelName
      }
    }
  }
}
</script>
<style lang="scss">
.p-calendar {
  .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;
    .p-datepicker-header{padding: 5px !important;}
    td { padding: 0px !important;
      a {padding: 0px !important;}
      span {padding: 0px !important; width: 30px !important; height: 30px !important;}
    }
  }
}
.dialog-report{ width: 70%;
  .box-report{margin: 0 auto; width: 500px;
    label{display: inline-block; vertical-align: text-top;}
    .disabled{background: #EEEEEE; border: 0 none; padding: 10px; margin: 0; opacity: 1 !important; width: 100%;}
  }
}

</style>